import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";

import "./styles.css";
import './fonts/ggsans-Bold.ttf';
import './fonts/ggsans-Normal.ttf';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          NotifyMe
        </h1>
         <h3>
         A Discord bot.
          </h3>
          <div className="discord-logo">
          <img src="static/images/notifyme-logo.png" alt="notifyme-logo"></img>
          </div>
          <h2>
            Coming soon.
          </h2>
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
